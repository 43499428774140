<template>
  <div class="home">
    <header>
    <div class="jumbotron jumbotron-lg jumbotron-fluid mb-0 pb-3 bg-primary position-relative">
        <div class="container-fluid text-white h-100">
            <div class="d-lg-flex align-items-center justify-content-between text-center pl-lg-5">
                <div class="col pt-4 pb-4">
                    <h2 class="display-4">专注助力小程序开发<br>APP开发，软件开发</h2>
                    <h5 class="font-weight-light mb-4" style="margin-top:30px">服务思维 全新升级</h5>
                    <a href="#operation" class="btn btn-lg btn-outline-white btn-round" style="margin-top:10px">了解合作流程</a>
                </div>
                <div style="max-height:280px;position:relative;z-index:1" class="col align-self-bottom align-items-right text-right h-max-380 h-xl-560 position-relative z-index-1">
                    <img src="/images/dashb.png" class="rounded img-fluid" style="max-height:430px;">
                </div>
            </div>
        </div>
    </div>
    <svg style="-webkit-transform:rotate(-180deg); -moz-transform:rotate(-180deg); -o-transform:rotate(-180deg); transform:rotate(-180deg); margin-top: -1px;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1440 126" xml:space="preserve">
    <path class="bg-primary" d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"></path>
    </svg>
</header>

    <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" style="margin-top:50px">
        <div class="container">
            <!-- Title -->
            <!-- Section title -->
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <h2 class=" mt-4">解决方案</h2>
                </div>
            </div>
            <!-- Card -->
            <div class="row mt-5">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-5.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">网站开发</h5>
                            <p class="text-muted mb-0">互联网的日益发展使得一个好的网站是一个企业的第一门面，我们助力于帮助您通过建设网站获得源源不断的流量.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-6.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">APP/小程序开发</h5>
                            <p class="text-muted mb-0">我们日常使用的最多的场景就是APP和小程序了，无论您是餐饮，娱乐，还是政务，出行，我们助力您生成最佳方案.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img src="/images/illustration-7.svg" class="img-fluid img-center" style="height: 150px;" alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">软件开发</h5>
                            <p class="text-muted mb-0">日常工作中，我们需要很多场景要借助电脑完成工作，我们助力于您获得便捷实用的各种工具，行业，以及管理软件.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="slice slice-lg" style="margin-top:80px;background:#f5f5f5;padding:50px 0px">
        <div class="container">
            <div class="py-6">
                <div class="row row-grid justify-content-between align-items-center">
                    <div class="col-lg-5 order-lg-2">
                        <h5 class="h3">关于我们</h5>
                        <p class="lead my-4">
                            团队成员均毕业于国内TOP级别院校，或来自于各大软件互联网厂商，多数成员具有10年以上的互联网、软件开发经验.
                        </p>
                        <div>
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="card mb-0 mr-lg-5">
                            <div class="card-body p-2">
                                <img alt="Image placeholder" src="/images/office.jpeg" class="img-fluid shadow rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </section>

    <section class="slice slice-lg bg-section-dark pt-5 pt-lg-8" style="margin-top:30px">
     
        <!-- Container -->
        <div class="container position-relative zindex-100">
            <div class="col">
                <div class="row justify-content-center">
                    <div class="col-md-10 text-center">
                        <div class="mt-4 mb-6" style="color:#333">
                            <h2 class="h1">
                                合作流程
                            </h2>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="slice pt-0" style="margin:30px 0px" id="operation">
        <div class="container position-relative zindex-100">
            <div class="row">
                <div class="col-xl-4 col-sm-6 mt-n7">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-warning badge-pill">1</span>
                            </div>
                            <div class="pl-4">
                                <h5 class="lh-130">前期沟通</h5>
                                <p class="text-muted mb-0">
                                    通过专人对接，跟您进行前期沟通，确保双方了解清楚需要完成的任务.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 mt-sm-n7">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-success badge-pill">2</span>
                            </div>
                            <div class="pl-4">
                                <h5 class="lh-130">方案报价</h5>
                                <p class="text-muted mb-0">
                                    根据您的实际，我们提供实际的方案以及相对应的报价给到您，供您参考.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-12 col-sm-6 mt-xl-n7">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-danger badge-pill">3</span>
                            </div>
                            <div class="pl-3">
                                <h5 class="lh-130">确定合作</h5>
                                <p class="text-muted mb-0">
                                    对公对私均可，我们将和您签署合同，标明我们交付的内容和时间.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-sm-6">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-warning badge-pill">4</span>
                            </div>
                            <div class="pl-4">
                                <h5 class="lh-130">开发反馈</h5>
                                <p class="text-muted mb-0">
                                    正式进入开发流程，我们将定期和您反馈进度，并和您沟通可能有的变更.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-success badge-pill">5</span>
                            </div>
                            <div class="pl-4">
                                <h5 class="lh-130">交付验收</h5>
                                <p class="text-muted mb-0">
                                    我们最终交付给您，您可进行测试验收，并提出修改意见。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-12 col-sm-6">
                    <div class="card">
                        <div class="d-flex p-5">
                            <div>
                                <span class="badge badge-danger badge-pill">6</span>
                            </div>
                            <div class="pl-3">
                                <h5 class="lh-130">顺利成交</h5>
                                <p class="text-muted mb-0">
                                    确认任务完成，支付尾款，我们将为您开具发票，保障您的权益.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

   
  </div>
</template>

<script>
import navbar from '../components/navbar.vue';
export default {
  name: 'HomeView',
  components: {
    navbar
  }
}
</script>

<style lang="scss">
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 508px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.alpha-7, .alpha-container:hover .alpha-7--hover {
    opacity: 0.7;
}
.bg-primary {
    background-color: #493ba9 !important;
    fill:#493ba9 !important;
}

.btn-outline-white{
color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: #fff;
    border-radius:25px;

    &:hover{
        color:#493ba9;
        background:white;
    }
}

.card{
  margin-bottom:30px;
  box-shadow:0px 0px 15px #f5f5f5;
  border-color:#f1f1f1;
}

.lh-130{
  color:#333;
  font-weight:bold;
}

#operation{
    .badge{
        font-size:22px;
    }
}
</style>
