<template>
  <div id="app">
    <navbar/>
    <router-view/>
    <bottom/>
  </div>
</template>

<script>
import navbar from './components/navbar.vue';
import bottom from './components/bottom.vue';
export default {
  name: 'HomeView',
  components: {
    navbar,
    bottom
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.slice{
    padding:20px 0px;
}
</style>
